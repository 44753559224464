import React, { useState, useEffect, useRef, useContext, Suspense, lazy } from "react";
import { OverlayContext } from "./cybersecurity-board-contestoleadership-organizzazione";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useRecoilState, useRecoilValue } from "recoil";
import dayjs from "dayjs";
import { setErrorRec, isMessageBoxVisibleRec, setMessageRec, isLoadingRec, reFetchIndicatorL4, reFetchIndicatorPlaces, isMessageBoxVisibleClosingRec, SelectedL3ID } from "./../../../recoil";

import SchedaGenerale from "./cybersecurity-board-gap-analysis-controlli-Edit/gap-analysis-Edit-1-SchedaGenerale";
import Infrastruttura from "./cybersecurity-board-gap-analysis-controlli-Edit/gap-analysis-Edit-3-Note";
// const Infrastruttura = lazy(() => import ("./\cybersecurity-board-infrastruttura-luoghi-Edit/organigramma-L4-Edit-3-Infrastruttura").then(module => ({ default: module.default })));
import Organizzazione from "./cybersecurity-board-gap-analysis-controlli-Edit/gap-analysis-Edit-2-Criteri";

// import Processi from "./\cybersecurity-board-infrastruttura-luoghi-Add/organigramma-L4-Add-4-Processi";
// import Parti from "./\cybersecurity-board-infrastruttura-luoghi-Add/organigramma-L4-Add-3-Infrastruttura";
//import Compliance from "./cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-luoghi-Add/infrastruttura-luoghi-Add-6-GestioneCompliance";
import Documentazione, {
  useInitiateUpload,
} from "./cybersecurity-board-gap-analysis-controlli-Edit/gap-analysis-Edit-7-Documentazione";
;

import { ButtonGrey, ButtonText } from "./../styled-components";

import {
  FrameTopGradient100,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteTitleAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  Backdrop,
  MessageContainer,
  CloseMessageButton,
  MessageboxText,
  BackdropTransparent,
  CreaSubMenuButtonMultiple,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "./../cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";

import {
  LuoghiAttachedFilesInfo,
  LuoghiComplianceDate,
  LuoghiDocumentsAttached,
  LuoghiDocumentsPath,
  LuoghiEmail,
  LuoghiFax,
  LuoghiID,
  LuoghiIdentificativo,
  LuoghiIndirizzo,
  LuoghiName,
  LuoghiNomeAbbreviato,
  LuoghiOrganizzazioneSelectedL1,
  LuoghiOrganizzazioneSelectedL1ID,
  LuoghiTelefono,
  addLuoghiConfirmedLV1List,
  addLuoghiOrganizzazioneDirettore,
  addLuoghiOrganizzazioneDirettoreID,
  addLuoghi_2_isDropdownOpenDirectors,
  addLuoghi_2_isDropdownOpenLV1,
  addLuoghiOrganizzazioneDirettoreCompliance,
  addLuoghiOrganizzazioneDirettoreComplianceID,
  addLuoghi_2_isDropdownOpenDirectorsCompliance,
} from "./cybersecurity-board-contestoleadership-infrastruttura-sub/Luoghi-Add-recoil";
import { URL_GAP_Analysis, URL_LV4List, URL_LV4List_short, URL_PlacesFullList, useApi, useToken } from "./../../../API";
import { PATH_Controlli, PATH_Luoghi } from "./../../../docPaths";
import { useControlliOverlay } from "./cybersecurity-board-gap-analysis-controlli";
import { GAP_categoria, GAP_controlID, GAP_descrizione_controllo, GAP_ID, GAP_notes_done, GAP_notes_ev, GAP_notes_todo, GAP_obbligo, GAP_obbligo_label, GAP_priority, GAP_priority_label, GAP_val_appr, GAP_val_calc, reFetchIndicatorGAPControlli } from "./gap-recoil";
import { FileListItem, editL4DocPath, editL4FileListItemState } from "./cybersecurity-board-contestoleadership-organizzazione-sub/L4-Edit-recoil";
import { addL4AttachedFilesInfo, addL4DocumentsAttached } from "./cybersecurity-board-contestoleadership-organizzazione-sub/L4-Add-recoil";

function generateUUID(): string {
  return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function useGenerateUUID(): string {
  const [gapID, setGapID] = useRecoilState<string>(GAP_ID);

  useEffect(() => {
    // Generate UUID and set it
    const newUUID = generateUUID();
    setGapID(newUUID);
    console.log("New place ID: ", newUUID);
  }, [setGapID]); // This effect should run only once when the component mounts

  return gapID;
}

export const useHandleSave = () => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);

  const [message, setMessage] = useRecoilState(setMessageRec);

  const { closeOverlay } = useContext(OverlayContext);
  const { post } = useApi();

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(LuoghiAttachedFilesInfo);
  const [complianceDate, setComplianceDate] = useRecoilState(LuoghiComplianceDate);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(LuoghiDocumentsAttached);
  const [documentsPath, setDocumentsPath] = useRecoilState(LuoghiDocumentsPath);


  // const [email, setEmail] = useRecoilState(LuoghiEmail);
  // const [fax, setFax] = useRecoilState(LuoghiFax);
  // const [ID, setID] = useRecoilState(LuoghiID);
  // const [identificativo, setIdentificativo] = useRecoilState(LuoghiIdentificativo);
  // const [indirizzo, setIndirizzo] = useRecoilState(LuoghiIndirizzo);
  // const [name, setName] = useRecoilState(LuoghiName);
  // const [nomeAbbreviato, setNomeAbbreviato] = useRecoilState(LuoghiNomeAbbreviato);
  // const [organizzazioneSelectedL1, setOrganizzazioneSelectedL1] = useRecoilState(LuoghiOrganizzazioneSelectedL1);
  // const [organizzazioneSelectedL1ID, setOrganizzazioneSelectedL1ID] = useRecoilState(LuoghiOrganizzazioneSelectedL1ID);
  // const [telefono, setTelefono] = useRecoilState(LuoghiTelefono);
  // const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(addLuoghiConfirmedLV1List);
  // const [organizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(addLuoghiOrganizzazioneDirettore);
  // const [organizzazioneDirettoreID, setOrganizzazioneDirettoreID] = useRecoilState(addLuoghiOrganizzazioneDirettoreID);
  // const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(addLuoghi_2_isDropdownOpenDirectors);
  // const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(addLuoghi_2_isDropdownOpenLV1);
   const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorGAPControlli);
  // const [organizzazioneDirettoreCompliance, setOrganizzazioneDirettoreCompliance] = useRecoilState(addLuoghiOrganizzazioneDirettoreCompliance);
  // const [organizzazioneDirettoreComplianceID, setOrganizzazioneDirettoreComplianceID] = useRecoilState(addLuoghiOrganizzazioneDirettoreComplianceID);
  // const [isDropdownOpenDirectorsCompliance, setIsDropdownOpenDirectorsCompliance] = useRecoilState(addLuoghi_2_isDropdownOpenDirectorsCompliance);


  const [gapID, setGapId] = useRecoilState(GAP_ID);
  const [controlID, setControlId] = useRecoilState(GAP_controlID);
  const [categoria, setCategoria] = useRecoilState(GAP_categoria);
  const [descrizione_controllo, setDescrizioneControllo] = useRecoilState(GAP_descrizione_controllo);
  const [notes_done, setNotesDone] = useRecoilState(GAP_notes_done);
  const [notes_ev, setNotesEv] = useRecoilState(GAP_notes_ev);
  const [notes_todo, setNotesTodo] = useRecoilState(GAP_notes_todo);
  const [obbligo, setObbligo] = useRecoilState(GAP_obbligo);
  const [priority, setPriority] = useRecoilState(GAP_priority);
  const [val_appr, setValueAppro] = useRecoilState(GAP_val_appr);
  const [val_calc, setValueCalc] = useRecoilState(GAP_val_calc);
  const [gapPrioritaLabel, setGapPrioritaLabel] = useRecoilState(GAP_priority_label);
  const [gapObbligoLabel, setGapObbligoLabel] = useRecoilState(GAP_obbligo_label);
 
  const [selectedControlID, ] = useRecoilState(SelectedL3ID);



  const { keycloak, initialized } = useKeycloak();

  const token = initialized && keycloak.authenticated ? keycloak.token : null;

  interface DataItem {
    id: string;
    control_id: string;
    control_category: string;
    control_description: string;
    control_duty: string;
    control_priority: string;
    control_value_1: number;
    control_value_2: number;
    control_note_1: string;
    control_note_2: string;
    control_note_3: string;
    has_documents_folder_link: string;
  
  }
  
  const { patch } = useApi();

  useEffect(() => {
    const fetchControlData = async () => {
      try {
      const response = await fetch(`${URL_GAP_Analysis}/${selectedControlID}`, {
        method: "GET", // Explicitly define the method, even if it's 'GET'
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Adding the Authorization header
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data:DataItem = await response.json();
      console.log(`Fetched asset data: `, data);

        if (data) {
          setGapId(data.id);
          setControlId(data.control_id);
          setCategoria(data.control_category);
          setDescrizioneControllo(data.control_description);
          setNotesDone(data.control_note_1);
          setNotesEv(data.control_note_3);
          setNotesTodo(data.control_note_2);
          setObbligo(data.control_duty);
          setPriority(data.control_priority);
          setValueAppro(data.control_value_2.toString()); // Convert number to string if necessary
          setValueCalc(data.control_value_1.toString()); // Convert number to string if necessary
        }
      } catch (error) {
        console.error("Error fetching control data:", error);
      }
    };

    fetchControlData();
  }, [selectedControlID]);

  const handleSave = async () => {
    if (!controlID) {
      setMessage("Si prega di compilare tutti i campi obbligatori: ID controllo");
      setIsMessageBoxVisible(true);
      return; // Exit the function early
    }

    const newData = {
        id: gapID,
        control_id: controlID,
        control_category: categoria,
        control_description: descrizione_controllo,
        control_duty: obbligo,
        control_priority: priority,
        control_value_1: parseFloat(val_calc),
        control_value_2: parseFloat(val_appr),
        control_note_1: notes_done,
        control_note_2: notes_todo,
        control_note_3: notes_ev,
        has_documents_folder_link: `${PATH_Controlli}/${controlID}`,
    };

    try {
      setIsLoading(true);
      console.log(JSON.stringify(newData));
      const responseData = await patch(URL_GAP_Analysis, newData);

      console.log("Controllo modificato con successo:", responseData);
      //setMessage("Controllo modificato con successo");
      //setIsMessageBoxVisible(true);
      closeOverlay();
      setRefetchIndicator((prev) => prev + 1);
    } catch (error) {
      console.error("Error creating Controls:", error);
      setMessage(`Error durante la creazione del controllo: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSave };
};

const MainComponent = () => {
  const token = useToken();

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(LuoghiAttachedFilesInfo);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(LuoghiDocumentsAttached);
  const [documentsPath, setDocumentsPath] = useRecoilState(LuoghiDocumentsPath);
 
  const [docPath, setDocPath] = useRecoilState(editL4DocPath);
  const [docAttached, setDocAttached] = useRecoilState(addL4DocumentsAttached);
  const [filesInfo, setFilesInfo] = useRecoilState(addL4AttachedFilesInfo);

  const { handleSave } = useHandleSave();
  const initiateUpload = useInitiateUpload();

  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);

  const [message, setMessage] = useRecoilState(setMessageRec);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorPlaces);

  // CUSTOM HOOKS

  useGenerateUUID();



  
  const [gapID, setGapId] = useRecoilState(GAP_ID);
  const [controlID, setControlId] = useRecoilState(GAP_controlID);
  const [categoria, setCategoria] = useRecoilState(GAP_categoria);
  const [descrizione_controllo, setDescrizioneControllo] = useRecoilState(GAP_descrizione_controllo);
  const [notes_done, setNotesDone] = useRecoilState(GAP_notes_done);
  const [notes_ev, setNotesEv] = useRecoilState(GAP_notes_ev);
  const [notes_todo, setNotesTodo] = useRecoilState(GAP_notes_todo);
  const [obbligo, setObbligo] = useRecoilState(GAP_obbligo);
  const [priority, setPriority] = useRecoilState(GAP_priority);
  const [val_appr, setValueAppro] = useRecoilState(GAP_val_appr);
  const [val_calc, setValueCalc] = useRecoilState(GAP_val_calc);
  const [gapPrioritaLabel, setGapPrioritaLabel] = useRecoilState(GAP_priority_label);
  const [gapObbligoLabel, setGapObbligoLabel] = useRecoilState(GAP_obbligo_label);

  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  const handleCloseAndNavigate = () => {
    closeOverlay();
    closeOverlayControlli();
    setIsMessageBoxVisible(false); // Close the message box
    setAttachedFilesInfo([]); // Assuming this resets to an empty array
    setDocumentsAttached([]); // Assuming this is a boolean that should be reset to false
    setDocumentsPath(""); // Assuming this resets to an empty string
    setDocPath('');
    setDocAttached([]);
    setFilesInfo([]);
 // Delay resetting the strings by 1 second
 setTimeout(() => {
  setDocumentsPath("");
  setDocPath("");
  setDocAttached([]);
  setFilesInfo([]);

  setGapId("");
  setControlId("");
  setCategoria("");
  setDescrizioneControllo("");
  setNotesDone("");
  setNotesEv("");
  setNotesTodo("");
  setObbligo("");
  setPriority("");
  setValueAppro("");
  setValueCalc("");
  setGapPrioritaLabel("");
  setGapObbligoLabel("");
}, 1000); // 1000 milliseconds = 1 second
  };

  ///////////////// SAVE AND UPLOAD /////////

  const handleSaveAndUpload = async () => {
    await handleSave();
    initiateUpload(); // Now you can call this function directly
    setDocPath('');
    setDocAttached([]);
    setFilesInfo([]);
    setDocPath('');
    setDocAttached([]);
    setFilesInfo([]);
    handleClose();

    setTimeout(() => {
      setDocPath("");
      setDocAttached([]);
      setFilesInfo([]);
  
      setGapId("");
      setControlId("");
      setCategoria("");
      setDescrizioneControllo("");
      setNotesDone("");
      setNotesEv("");
      setNotesTodo("");
      setObbligo("");
      setPriority("");
      setValueAppro("");
      setValueCalc("");
      setGapPrioritaLabel("");
      setGapObbligoLabel("");
    }, 1000); // 1000 milliseconds = 1 second
  };

  ///////////////// MESSAGE ANNULLA BUTTON /////////

  const { closeOverlayControlli, toggleOverlayAdd2 } = useControlliOverlay();

  const handleClose = () => {
    //closeOverlay();
    closeOverlayControlli();
    setDocPath('');
    setDocAttached([]);
    setFilesInfo([]);
    setDocPath('');
    setDocAttached([]);
    setFilesInfo([]);

    setTimeout(() => {
      setDocPath("");
      setDocAttached([]);
      setFilesInfo([]);
  
      setGapId("");
      setControlId("");
      setCategoria("");
      setDescrizioneControllo("");
      setNotesDone("");
      setNotesEv("");
      setNotesTodo("");
      setObbligo("");
      setPriority("");
      setValueAppro("");
      setValueCalc("");
      setGapPrioritaLabel("");
      setGapObbligoLabel("");
    }, 1000); // 1000 milliseconds = 1 second
  };

  ////////////////////CHANGE ACTIVE COMPONENT///////////

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      case "Organizzazione":
        return <Organizzazione />;
      case "Infrastruttura":
        return <Infrastruttura />;
      // case "ProcessiGenerali":
      //   return <Processi />;
      // case "PartiInteressate":
      //   return <Parti />;
      //case "SistemaCompliance":
      //  return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>MODIFICA CONTROLLO {controlID}</OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SchedaGenerale")} to="/Cybersecurity/Controlli/ModificaControllo/1">
            Scheda generale
          </CreaSubMenuButtonMultiple>

          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Organizzazione")}
            to="/Cybersecurity/Controlli/ModificaControllo/2"
          >
            Criteri
          </CreaSubMenuButtonMultiple>

          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/Controlli/ModificaControllo/3"
          >
            Roadmap
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/Infrastruttura/Luoghi/CreaLuoghi/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Infrastruttura/UnitaOperative/CreaL2/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SistemaCompliance")}
            to="/Cybersecurity/Infrastruttura/Luoghi/CreaLuoghi/6"
          >
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Documentazione")}
            to="/Cybersecurity/Controlli/ModificaControllo/7"
          >
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>
        <Suspense fallback={<div>Loading...</div>}>{renderActiveComponent()}</Suspense>
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={handleClose}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSaveAndUpload}>
          <ButtonText>Salva</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default MainComponent;
