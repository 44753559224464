import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import CancelIcon from "@mui/icons-material/Clear";

import {
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputDescriptionTextDouble,
  InputDescriptionTextTriple,
  InputTextbox,
  InputTextboxDouble,
  InputTextboxTriple,
  InputTextboxTripleRealligned,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
} from "./../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { SelectedL4ID } from "./../../../../recoil";
import { InputContainer50 } from "./../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import {
  LuoghiAttachedFilesInfo,
  LuoghiDocumentsAttached,
  LuoghiDocumentsPath,
  LuoghiEmail,
  LuoghiFax,
  LuoghiID,
  LuoghiIdentificativo,
  LuoghiIndirizzo,
  LuoghiName,
  LuoghiNomeAbbreviato,
  LuoghiTelefono,
  LuoghiComplianceDate,
} from "../cybersecurity-board-contestoleadership-infrastruttura-sub/Luoghi-Add-recoil";
import { DatePicker } from "@mui/x-date-pickers";
import { TAB_DESCRIPTION_CONTROLLI_1, TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_LUOGHI_1 } from "./../tabs-description";
import { GAP_categoria, GAP_controlID, GAP_descrizione_controllo, GAP_obbligo, GAP_obbligo_label, GAP_priority, GAP_priority_label, GAP_val_appr, GAP_val_calc } from "../gap-recoil";
import {
  CustomDropdownItem,
  CustomDropdownList,
  DropDownMenuScrollbar,
  TriangleContainer,
} from "../cybersecurity-board-contestoleadership-compliance-sub/cybersecurity-board-gestionecompliance-organigramma-styled-components";

const useToken = () => {
  const { keycloak, initialized } = useKeycloak();
  return initialized && keycloak.authenticated ? keycloak.token : null;
};

type PersonItem = {
  id: string;

  surname: string;
  name: string;
  has_personal_card: string;
  member_of_formal_organization: string;
  reports_to: string;
  has_smart_working_site: string;
  documents_folder_link: string;
  based_at: string;

  national_identification_number: string;
  date_of_birth: string;
  has_place_of_birth: string;
  has_primary_residence: string;
  has_postal_address: string;
  personal_email: string;
  work_email: string;
  work_mobile_phone: string;
  personal_mobile_phone: string;
  has_identity_document: string;
  has_citizenship: string;
};

type PersonApiContentItem = {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  surname: string;
  name: string;
  has_personal_card: string;
  member_of_formal_organization: string;
  reports_to: string;
  has_smart_working_site: string;
  documents_folder_link: string;
  based_at: string;
  personal_card: {
    id: string;
    creationTs: string;
    updateTs: string;
    createdBy: string;
    updatedBy: string;
    national_identification_number: string;
    date_of_birth: string;
    has_place_of_birth: string;
    has_primary_residence: string;
    has_postal_address: string;
    personal_email: string;
    work_email: string;
    work_mobile_phone: string;
    personal_mobile_phone: string;
    has_identity_document: string;
    has_citizenship: string;
  };
};

const MainComponent = () => {
  const token = useToken();

  const [data, setData] = useState<PersonItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  ////////////RECOIL

  const [gapId, setGapId] = useRecoilState(GAP_controlID);
  const [gapCategoria, setGapCategoria] = useRecoilState(GAP_categoria);
  const [gapDescrizione, setGapDescrizione] = useRecoilState(GAP_descrizione_controllo);

  const [gapValCalc, setGapValCalc] = useRecoilState(GAP_val_calc);
  const [gapValAppr, setGapValAppr] = useRecoilState(GAP_val_appr);

  const [gapObbligo, setGapObbligo] = useRecoilState(GAP_obbligo);
  const [gapObbligoLabel, setGapObbligoLabel] = useRecoilState(GAP_obbligo_label);
  const [gapPriorita, setGapPriorita] = useRecoilState(GAP_priority);
  const [gapPrioritaLabel, setGapPrioritaLabel] = useRecoilState(GAP_priority_label);

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(LuoghiAttachedFilesInfo);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(LuoghiDocumentsAttached);
  const [docPath, setDocPath] = useRecoilState(LuoghiDocumentsPath);
  const [email, setEmail] = useRecoilState(LuoghiEmail);
  const [fax, setFax] = useRecoilState(LuoghiFax);
  const [ID, setID] = useRecoilState(LuoghiID);
  const [identificativo, setIdentificativo] = useRecoilState(LuoghiIdentificativo);
  const [indirizzo, setIndirizzo] = useRecoilState(LuoghiIndirizzo);
  const [name, setName] = useRecoilState(LuoghiName);
  const [nomeAbbreviato, setNomeAbbreviato] = useRecoilState(LuoghiNomeAbbreviato);
  const [telefono, setTelefono] = useRecoilState(LuoghiTelefono);
  const [complianceDate, setComplianceDate] = useRecoilState(LuoghiComplianceDate);

  type ObbligoItem = {
    label: string;
    value: string;
    
  };
  
  type PrioritaItem = {
    label: string;
    value: string;
    
  };
  
  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownRef2 = useRef<HTMLDivElement>(null);

  const [dataObbligo, setDataObbligo] = useState<ObbligoItem[]>([]);
  const [dataPriorita, setDataPriorita] = useState<PrioritaItem[]>([]);

  const ClearLV1 = () => {
    setGapObbligo("");
    setGapObbligoLabel("");
  };

  const ClearLV2 = () => {
    setGapPriorita("");
    setGapPrioritaLabel("");
  };

  const handleDropdownToggle = () => {
    // Clear the director name in the input box
    setGapObbligo("");
    // Reset the director ID
    setGapObbligoLabel("");
    // Open the dropdown if it is not already open
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDropdownToggle2 = () => {
    // Clear the director name in the input box
    setGapPriorita("");
    // Reset the director ID
    setGapPrioritaLabel("");
    // Open the dropdown if it is not already open
    setIsDropdownOpen2(!isDropdownOpen2);
  };


  const handleOptionSelect = (selectedDirectorId: string) => {
    // Find the director name corresponding to the selected ID
    const selectedDirectorName = dataObbligo.find(
      (item) => item.value === selectedDirectorId
    )?.label;

    if (selectedDirectorName) {
      // Update the director name in the input box
      setGapObbligoLabel(selectedDirectorName);

      // Update the director ID
      setGapObbligo(selectedDirectorId);
    }

    // Close the dropdown
    setIsDropdownOpen(false);
  };

  
  const handleOptionSelect2 = (selectedDirectorId: string) => {
    // Find the director name corresponding to the selected ID
    const selectedDirectorName = dataPriorita.find(
      (item) => item.value === selectedDirectorId
    )?.label;

    if (selectedDirectorName) {
      // Update the director name in the input box
      setGapPrioritaLabel(selectedDirectorName);

      // Update the director ID
      setGapPriorita(selectedDirectorId);
    }

    // Close the dropdown
    setIsDropdownOpen2(false);
  };

  const directorNameToId = new Map(
    dataObbligo.map((item) => [item.label, item.value])
  );

  const directorNameToId2 = new Map(
    dataPriorita.map((item) => [item.label, item.value])
  );


  const handleDirectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setGapObbligoLabel(value);

    // If the value is a director name, also set the director ID
    if (directorNameToId.has(value)) {
      setGapObbligo(directorNameToId.get(value) || "");
      console.log(directorNameToId.get(value) || "");
    }
  };

  useEffect(() => {
    setDataObbligo([
      
      { label: "Libero", value: "libero" },
      { label: "Consigliato", value: "consigliato" },
      { label: "Obbligatorio", value: "obbligatorio" },
    ]);
  }, []);

  const handleDirectorChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setGapPrioritaLabel(value);

    // If the value is a director name, also set the director ID
    if (directorNameToId2.has(value)) {
      setGapPriorita(directorNameToId2.get(value) || "");
      console.log(directorNameToId2.get(value) || "");
    }
  };

  useEffect(() => {
    setDataObbligo([
      
      { label: "Libero", value: "libero" },
      { label: "Consigliato", value: "consigliato" },
      { label: "Obbligatorio", value: "obbligatorio" },
    ]);
  }, []);

  useEffect(() => {
    setDataPriorita([
      
      { label: "Non definita", value: "nd" },
      { label: "Bassa", value: "bassa" },
      { label: "Media", value: "media" },
      { label: "Alta", value: "alta" },
    ]);
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Add a type guard for event.target
      if (
        dropdownRef.current &&
        event.target instanceof Node &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside2(event: MouseEvent) {
      // Add a type guard for event.target
      if (
        dropdownRef2.current &&
        event.target instanceof Node &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setIsDropdownOpen2(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              ID controllo
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox placeholder="" value={gapId} onChange={(e) => setGapId(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              Categoria
              {/* <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip> */}
            </InputDescriptionText>
            <InputTextbox placeholder="" value={gapCategoria} onChange={(e) => setGapCategoria(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionTextTriple>
              Descrizione controllo {gapDescrizione.length}/500
              {/* <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip> */}
            </InputDescriptionTextTriple>

            <InputTextboxTripleRealligned maxLength={255} placeholder="" value={gapDescrizione} onChange={(e) => setGapDescrizione(e.target.value)} />
            {/* <IconContainer /> */}
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
        <InputContainer>
            <InputDescriptionText>Obbligo</InputDescriptionText>

            <InputTextbox
              list="obbligo-list"
              value={gapObbligoLabel}
              onChange={handleDirectorChange}
              onClick={handleDropdownToggle}
              placeholder="Seleziona"
            />
            {gapObbligo && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6", // Change the color if needed
                }}
                onClick={ClearLV1} // Clears the input field
              />
            )}
            <TriangleContainer>
              {isDropdownOpen ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpen && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRef}>
                  {dataObbligo
                    .filter((item) =>
                      item.label
                        .toLowerCase()
                        .includes(item.label.toLowerCase())
                    )
                    .map((filteredItem, index) => (
                      <CustomDropdownItem
                        key={index}
                        onClick={() =>
                          handleOptionSelect(filteredItem.value)
                        }
                      >
                        {filteredItem.label}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          {/* <InputContainer>
            <InputDescriptionText>Priorità</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={gapPriorita}
              onChange={(e) => setGapPriorita(e.target.value)}
            />
            <IconContainer />
          </InputContainer> */}

          <InputContainer>
            <InputDescriptionText>Priorità</InputDescriptionText>

            <InputTextbox list="priorita-list" value={gapPrioritaLabel} onChange={handleDirectorChange2} onClick={handleDropdownToggle2} placeholder="Seleziona" />
            {gapPriorita && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6", // Change the color if needed
                }}
                onClick={ClearLV2} // Clears the input field
              />
            )}
            <TriangleContainer>
              {isDropdownOpen2 ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpen2 && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRef2}>
                  {dataPriorita
                    .filter((item) => item.label.toLowerCase().includes(item.label.toLowerCase()))
                    .map((filteredItem, index) => (
                      <CustomDropdownItem key={index} onClick={() => handleOptionSelect2(filteredItem.value)}>
                        {filteredItem.label}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Valutazione calcolata</InputDescriptionText>
            <InputTextbox disabled={true} placeholder="" value={gapValCalc} onChange={(e) => setGapValCalc(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Valutazione approvata</InputDescriptionText>
            <InputTextbox placeholder="" value={gapValAppr} onChange={(e) => setGapValAppr(e.target.value)} />
            <IconContainer />
          </InputContainer>

          {/* <InputContainer>
            <InputDescriptionText>Fax</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={fax}
              onChange={(e) => setFax(e.target.value)}
            />
            <IconContainer />
          </InputContainer> */}
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>{TAB_DESCRIPTION_CONTROLLI_1 ? TAB_DESCRIPTION_CONTROLLI_1 : TAB_DESCRIPTION_GLOBAL}</DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
